import React from "react";

function ContactPage() {
  return (
    <div>
      <h1>Contact Us</h1>
      <p>Insert your contact information and form here...</p>
    </div>
  );
}

export default ContactPage;
