import React from "react";

function AboutPage() {
  return (
    <div>
      <h1>About Us</h1>
      <p>Insert your about content here...</p>
    </div>
  );
}

export default AboutPage;
